<template>
  <div class="studyplan">
    <div v-if="myPlan.length > 0">
      <div class="centerWidth marginAuto"
           style="position:relative;text-align:left;margin-top:30px;">
        <button style="margin-left:40px;"
                class="cursorP editbtn"
                @click="editcheck">{{editTurn ? '完成' : '编辑'}}</button>
      </div>
      <div class="planList marginAuto centerWidth"
           v-for="(im,ix) in myPlan"
           :key="ix">
        <div class="timeTitle flex alignCenter">
          <div class="timeTitleLeft"></div>
          <div class="timeTitleRight c33 fontW">
            {{im.time}}
          </div>
        </div>
        <div class="courseList flex spacearound alignCenter flexWrap"
             :class=" im.data.length < 2 ? 'w590' : ''">
          <div class="courseInfo flex spacebetween"
               v-for="(jm,jx) in im.data"
               :key="jx">
            <div class="courseInfoLeft">
              <img :src="jm.face_img"
                   alt="">
              <img src="@/assets/img/common/zhiboBG.png"
                   v-show="jm.type == 1"
                   class="zhibo"
                   alt="">
              <!-- <p class="courseInfoLeftTime">{{jm.study_count}}人在学习</p> -->
              <p class="courseInfoLeftTime">{{jm.type == 1 ? jm.direct_time : jm.plan_time}}</p>
            </div>
            <div class="courseInfoRight flex flexColumn spacebetween">
              <div class="courseInfoRightName c33 moreHidden">
                {{jm.title}}
              </div>
              <div class="courseInfoRightTeacher flex alignCenter spacebetween">
                <div class="studyTeacher flex alignCenter "
                     v-for="(km,kx) in jm.lecturer_info"
                     :key="kx"
                     v-show="kx < 2">
                  <div class="teacher flex alignCenter fwtHidden"
                       style="width:85px;">
                    <img :src="km.portrait"
                         style="width: 24px;height: 24px;border-radius: 50px;margin-right: 10px;"
                         alt="">
                    {{km.real_name}}
                  </div>
                </div>
                <div class="studyNum">
                  {{jm.study_count}}人在学
                </div>
              </div>
              <div class="courseInfoRightOper flex spacebetween alignCenter">
                <span v-if="jm.study_status == 1">已完成</span>
                <span v-else>{{jm.rate == 0 ? '未观看' : `${parseFloat(jm.rate).toFixed(2)}%`}}</span>
                <div>
                  <span style="font-size:14px;color:#47D7E3;">{{jm.sp_type == 1 ? '单位必修课' : '我的选修课'}}</span>
                  <button v-if="jm.sp_type == 2 && editTurn"
                          class="cursorP"
                          @click="removePlan(jm, jx, im, ix)"
                          style="background:#FD3838;margin-left:20px;">删除</button>
                  <button style="margin-left:20px;"
                          v-else
                          class="cursorP"
                          @click="goother({path:'/play',query:{id:jm.id}})">立即学习</button>
                </div>
              </div>
            </div>
          </div>
          <div style="opacity:0;width:550px;"
               v-for="val in 1"
               :key="val"></div>
        </div>
      </div>
    </div>
    <temp-data v-else
               shouwtext="暂无学习计划或未登录"></temp-data>
    <div style="width:100%;height:50px;"></div>
  </div>
</template>
<script>
import { del_plan } from '@/utils/Api/courseList'
import { myplan_bytime } from '@/utils/Api/userList'
import tempData from '@/components/tempData'
export default {
  name: 'studyplan',
  components: { tempData },
  data () {
    return {
      myPlan: [],
      editTurn: false,            // 编辑学习计划列表
    }
  },
  created () {
    this.initialBefore()
  },
  methods: {
    editcheck () {
      this.editTurn = !this.editTurn
      if (this.editTurn) {
        this.$message.info('单位必修课不可操作！')
      }
    },
    async removePlan (jm, jx, im, ix) {
      const self = this
      this.$confirm('确认删除此计划？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.removeNext(jm, jx, im, ix)
      }).catch(() => {
      })
    },
    async removeNext (jm, jx, im, ix) {
      const self = this
      let res = await del_plan({ plan_ids: jm.sp_id })
      if (res.length < 1) {
        im.data.splice(jx, 1)
        if (im.data.length < 1) {
          self.myPlan.splice(ix, 1)
        }
        this.$message.success('删除成功')
        return
      }
      this.$message.error('删除失败！')

    },
    initialBefore () {
      let params = {
        page: 1,
        limit: 9999999
      }
      this.initial(params)
    },
    async initial (params) {
      let cbk = await myplan_bytime(params)
      this.myPlan = cbk
    }
  }
}
</script>
<style lang="scss" scoped>
.editbtn {
  /* position: fixed;
    left: 60px;
    top: 390px; */
  padding: 0;
  width: 92px;
  height: 50px;
  font-size: 22px;
}
.w590 {
  width: 590px;
}
button {
  width: 76px;
  height: 26px;
  background: #47d7e3;
  font-size: 14px;
  color: #fff;
}
.courseList {
  .courseInfo {
    width: 550px;
    overflow: hidden;
    height: 126px;
    margin-top: 28px;
    .courseInfoRight {
      width: 302px;
      height: 100%;
      .courseInfoRightOper {
        margin-top: 9px;
        // align-items: flex-end;  // 修改设计图后注释掉了，防止再改图
        span {
          font-size: 14px;
          color: #ff3838;
        }
        button {
          width: 76px;
          height: 26px;
          background: #47d7e3;
          font-size: 14px;
          color: #fff;
        }
      }
      .courseInfoRightTeacher {
        margin-top: 12px;
        .studyTeacher {
          .teacher {
            img {
            }
          }
        }
      }
      .courseInfoRightName {
        font-size: 18px;
        line-height: 27px;
        text-align: left;
      }
    }
    .courseInfoLeft {
      width: 216px;
      height: 126px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .courseInfoLeftTime {
        width: 100%;
        height: 30px;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        font-size: 15px;
        text-align: center;
        line-height: 30px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  // min-width: 589px;
  // max-width: 1158px;
  margin-left: 42px;
  background: #fff;
  min-height: 126px;
  padding-bottom: 33px;
  margin-top: 37px;
  border-radius: 7px;
}
.studyplan {
  width: 100%;
  background: #f7f8fa;
  min-height: 500px;
  overflow: hidden;
  .planList {
    margin-top: 37px;
  }
  .timeTitleLeft {
    width: 22px;
    height: 22px;
    border-radius: 50px;
    background: #47d7e3;
  }
  .timeTitleRight {
    font-size: 18px;
    padding: 9px 20px;
    background: #fff;
    margin-left: 20px;
  }
  .zhibo {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 52px !important;
    height: 22px !important;
  }
}
</style>