import * as api from './http'

/* 学习记录 */
export function my_look_log(params){
    return api.postApi('Course/my_look_log', params)
}

/* 删除学习计划 */
export function del_plan(params){
    return api.postApi('My/del_plan', params)
}